import React from "react";
import '../App.css';

function AboutMe() {
    return (
        <>
            <div class="scroll-container">
                <a href="#top" id="tothetop"><button id="tothetopbutton">To Top</button></a>
            </div>
        </>
    )
}

export default AboutMe